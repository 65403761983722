<!--  -->
<template>
  <div class=''>
    <div><span class="pageTop">成员管理</span> <el-button @click="EditAllInfo()" type="success" plain>{{ '批量修改个人信息表'
        }}</el-button> </div>
    <!-- <button @click="$router.push({ name: 'examplePage3' })">跳转</button> -->
    <el-divider></el-divider>

    <el-input style="width: 500px" placeholder="请输入用户姓名检索" v-model="search" clearable prefix-icon="el-icon-search">
    </el-input>

    <el-table :data="tableData.filter(data => !search || data.uname.toLowerCase().includes(search.toLowerCase()))"
      style="width: 100%" :header-cell-style="headerCellStyle" :cell-style="cellStyle">
      <el-table-column type="index">
      </el-table-column>
      <el-table-column v-if="this.$store.state.module.moduleName === 'health_station'" label="手机号" prop="phone">
      </el-table-column>
      <el-table-column label="用户姓名" prop="uname">
      </el-table-column>
      <el-table-column label="基础信息">
        <el-table-column label="性别" prop="gender">
        </el-table-column>
        <el-table-column label="年龄" prop="age">
        </el-table-column>
        <el-table-column label="身高" prop="height">
        </el-table-column>
        <el-table-column label="体重" prop="weight">
        </el-table-column>
        <el-table-column label="位置">
          <template slot-scope="scope">
            <el-button @click="goLocation(scope.row)" type="primary" plain>{{ '查看' }}</el-button>
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column prop="userInfo" label="个人信息表">
        <template slot-scope="scope">
          <el-button @click="psInfo(scope.row)" type="primary" plain>{{ '查看' }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="体检报告">
        <template slot-scope="scope">
          <el-button @click="ExamReport(scope.row)" type="primary" plain>{{ '查看' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="能力评估">
        <template slot-scope="scope">
          <el-button @click="OlderReport(scope.row)" type="primary" plain>{{ '查看' }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="体温单" v-if="this.$store.state.module.moduleName == 'older_station'">
        <template slot-scope="scope">
          <el-button @click="GetSanCeDan(scope.row)" type="primary" plain>{{ '查看' }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="健康信息" prop="userHeal">
        <template v-slot="scope3">
          <el-popover placement="right" trigger="click" width="auto">
            <div style="text-align: center;font-size: 20px">请选择查询项目</div>
            <el-button @click="getECG(scope3.row.uid)">
              静态心电
            </el-button>
            <el-button @click="getECGHolter(scope3.row.uid)">
              动态心电
            </el-button>
            <el-button @click="getBloodPressure(scope3.row.uid)">
              血压
            </el-button>
            <el-button @click="getBloodOxygen(scope3.row.uid)">
              血氧
            </el-button>
            <!-- <el-button @click="getBloodSugar(scope3.row.uid)">
              血糖
            </el-button> -->
            <el-button @click="getBodyTemprature(scope3.row.uid)">
              体温
            </el-button>
            <el-button @click="getThreeInOne(scope3.row.uid)">
              血糖|尿酸|总胆固醇
            </el-button>
            <!-- <el-button @click="getBodyFat(scope3.row.uid)">
              体脂
            </el-button> -->
            <el-button @click="getUrine(scope3.row.uid)">
              尿常规
            </el-button>
            <!-- <el-button @click="get8ECG(scope3.row.uid)">
              8导联静态
            </el-button>
            <el-button @click="get8ECGHolter(scope3.row.uid)">
              8导联holter
            </el-button> -->


            <el-button slot="reference" type="info">查看</el-button>
          </el-popover>
        </template>

      </el-table-column>

      <el-table-column label="导出">
        <template slot-scope="scope">
          <el-button @click="GetMonthData(scope.row.uid, scope.row.uname, scope.row.gender, scope.row.age)"
            type="success" plain>{{ '导出' }}</el-button>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template v-slot:default="scope4">
          <el-button size="mini" type="danger" @click="handleDelete(scope4.$index, scope4.row)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>
    <br>
    <br>
    <div v-if="this.$store.state.module.moduleName === 'health_station'" style="padding-top: 40px">
      <div style="font-size: 24px;padding-bottom: 10px">添加新用户</div>
      <el-col :span="6">
        <el-input :rows="1" placeholder="请输入用户手机号" v-model="userNameOrPhone">
        </el-input>
      </el-col>
      <el-col :span="4" :offset="1">
        <el-button @click="addNewUser()" type="primary">添加</el-button>
      </el-col>
    </div>

    <div v-if="psInfoVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="psInfoVisible">
        <ps_information :userId="this.uidCurrent" />
      </el-dialog>
    </div>
    <div v-if="ExamReportVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="ExamReportVisible">
        <exam_report :userId="this.uidCurrent" />
      </el-dialog>
    </div>

    <div v-if="OlderReportVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="OlderReportVisible">
        <older_report :userId="this.uidCurrent" :reportStatus="0" />
      </el-dialog>
    </div>

    <div v-if="SanCeDanVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog :visible.sync="SanCeDanVisible" width="80%">
        <SanCeDan :userId="this.uidCurrent" />
      </el-dialog>
    </div>

    <div v-if="EditVisible"> <!-- 关闭后销毁dialog框 -->
      <el-dialog title="批量修改个人信息表" width="30%" :visible.sync="EditVisible">
        <div>修改说明：</div>
        <div>&nbsp;</div>
        <div>1. 点击<el-button type="success" @click="DownloadAllInfo()">下载所有成员信息</el-button>按钮下载Excel表</div>
        <div>&nbsp;</div>
        <div>2. 在Excel表中修改信息</div>
        <div>&nbsp;</div>
        <el-upload action="" :auto-upload="false" :on-change="handleFileChange" :show-file-list="false">
          3. 点击<el-button type="success">上传修改后的Excel表</el-button>按钮上传修改后的Excel表
        </el-upload>
      </el-dialog>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import ps_information from '../../personal_module/ps_info/ps_information.vue'
import exam_report from '../../personal_module/records/report.vue'
import older_report from '../../older_station/evaluation_report/evaluationReport.vue'
import SanCeDan from '../../personal_module/records/SanCeDan/chartView.vue'
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { exportJsonToExcel } from '@/utils/exportExcel';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    ps_information,
    exam_report,
    SanCeDan,
    older_report
  },
  data() {
    //这里存放数据
    return {
      size: '',
      dialogVisible: false,

      locData: [],

      search: '',
      input: '',

      tableData: [{
        uid: 2,
        phone: '123',
        realName: '小王',
      },
      ],

      DownloadData: [],

      // DownloadData:[{data:'2024-06-11',temp: '37.6', highpressure:130, lowpressure:79, xinlv:71},],

      psInfoVisible: false,
      uidCurrent: '',
      ExamReportVisible: false,
      OlderReportVisible: false,
      userNameOrPhone: '',
      addUid: 2,
      SanCeDanVisible: false,
      EditVisible: false,
      headerMap: {
        iid: '请勿修改1',
        uid: '请勿修改2',
        real_name: '真实姓名',
        height: '身高',
        weight: '体重',
        gender: '性别',
        age: '年龄',
        ID_card: '身份证号',
        birthday: '出生日期',
        area_address: '地址',
        census_register_address: '户籍地址',
        residential_type: '居住类型',
        nationality: '民族',
        company: '公司',
        education: '学历',
        job: '职业',
        marry: '婚姻状况',
        blood_type: '血型',
        self_evaluation: '自我评价',
        mentality: '心理状态',
        insurance_payment_mode: '保险缴费方式',
        disability: '残疾情况',
        ventilation: '通风情况',
        fuel:"燃料类型",
        water: '用水情况',
        toilet: '厕所类型',
        corral: '畜栏情况',
        allergy_history: '过敏史',
        exposure_history: '暴露史',
        disease_history: '疾病史',
        operation_history: '手术史',
        family_disease: '家族病史',
        injury_history: '受伤史',
        transfusion_history: '输血史',
        inheri_desease_history: '遗传病史'
      },
      file: null,

    };
  },
  //监听属性 类似于data概念
  computed: {
    crossPlatformStatus: {
      get() { return this.$store.state.module.crossPlatform },
      set(val) { this.$store.commit('module/updateCrossPlatform', val) }
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    headerCellStyle() {
      return { textAlign: 'center' };
    },
    cellStyle() {
      return { textAlign: 'center' };
    },
    groupDataByDate(data, type) {
      return data.reduce((acc, item) => {
        const date = item.date.split(' ')[0];
        if (!acc[date] || new Date(item.date) > new Date(acc[date].date)) {
          if (type === 'temp') {
            acc[date] = { date: item.date, temp: item.temp };
          } else if (type === 'bp') {
            acc[date] = { date: item.date, highpressure: item.highpressure, lowpressure: item.lowpressure, xinlv: item.xinlv };
          }
        }
        return acc;
      }, {});
    },

    async GetTempAndBP(Uid) {
      var now = new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1; // getMonth() 返回 0-11，所以需要 +1
      this.startTime = `${year}-${String(month).padStart(2, '0')}-01`;
      // 获取当前月份的最后一天
      const endOfMonth = new Date(year, month, 0);
      this.endTime = `${year}-${String(month).padStart(2, '0')}-${String(endOfMonth.getDate()).padStart(2, '0')}`;
      console.log("当月时间格式")
      console.log(this.startTime)
      console.log(this.endTime)

      await this.$http({
        url: this.$http.adornUrl("/personal/personaltemperature/listByTime"),
        method: "post",
        data: this.$http.adornData({ uid: Uid, startTime: this.startTime, endTime: this.endTime }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          this.TempData = data.personalTemperatureEntities;
          console.log("看看体温数据");
          console.log(this.TempData);
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });

      this.header = { "Cross-Platform-Verification": "Cross-Platform-Access" }
      this.$http.adornHeader(this.header)
      await this.$http({
        url: this.$http.adornUrl("/personal/personalbloodpressure/listByTime"),
        method: "get",
        params: this.$http.adornParams({ uid: Uid, startTime: this.startTime, endTime: this.endTime }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.loadingShow = false
          this.BPData = data.personalBloodPressureEntities;
          console.log("看看血压数据");
          console.log(this.BPData);
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      });
    },

    async GetMonthData(Uid, uname, gender, age) {
      await this.GetTempAndBP(Uid)
      console.log("等了吗")
      this.DownloadData = []
      const tempDataMap = this.groupDataByDate(this.TempData, 'temp');
      const bpDataMap = this.groupDataByDate(this.BPData, 'bp');
      let currentDate = new Date(this.startTime);
      const endDate = new Date(this.endTime);
      while (currentDate <= endDate) {
        const dateStr = currentDate.toISOString().split('T')[0];
        this.DownloadData.push({
          date: dateStr,
          temp: tempDataMap[dateStr] ? tempDataMap[dateStr].temp : '',
          highpressure: bpDataMap[dateStr] ? bpDataMap[dateStr].highpressure : '',
          lowpressure: bpDataMap[dateStr] ? bpDataMap[dateStr].lowpressure : '',
          xinlv: bpDataMap[dateStr] ? bpDataMap[dateStr].xinlv : ''
        });
        currentDate.setDate(currentDate.getDate() + 1);
      }

      console.log("看看整合数据")
      console.log(this.DownloadData)
      this.download_table(uname, gender, age)

    },
    download_table(uname, gender, age) {
      // Create a new array with updated key names
      const excel_total = this.DownloadData.map(item => {
        const newItem = { ...item }; // Clone the item to avoid modifying the original data
        console.log('download')
        console.log(newItem)
        newItem['时间'] = newItem.date;
        newItem['体温(℃)'] = newItem.temp;
        newItem['高压(mmHg)'] = newItem.highpressure;
        newItem['低压(mmHg)'] = newItem.lowpressure;
        newItem['心率(次/分)'] = newItem.xinlv;


        delete newItem.date;
        delete newItem.temp;
        delete newItem.highpressure;
        delete newItem.lowpressure;
        delete newItem.xinlv;


        return newItem;
      });
      const columnNames = ['时间', '体温(℃)', '高压(mmHg)', '低压(mmHg)', '心率(次/分)'];
      const wb = XLSX.utils.book_new();
      const worksheetData = [
        [`姓名：${uname}`, `性别：${gender}`, `年龄：${age}`],
        columnNames,
        ...excel_total.map(item => Object.values(item))
      ];

      // Create a new worksheet from the worksheetData
      const ws1 = XLSX.utils.aoa_to_sheet(worksheetData);
      // const ws1 = XLSX.utils.json_to_sheet(excel_total);
      XLSX.utils.book_append_sheet(wb, ws1, "健康信息");
      const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' });
      // Save the workbook
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), '健康信息统计.xlsx');

    },
    // 获取个人信息表
    psInfo(e) {
      this.psInfoVisible = true
      this.uidCurrent = e.uid
      console.log(this.uidCurrent)
      console.log("看看psInfoVisible", this.psInfoVisible)
    },
    ExamReport(e) {
      this.ExamReportVisible = true
      this.uidCurrent = e.uid
    },
    OlderReport(e) {
      this.OlderReportVisible = true
      this.uidCurrent = e.uid
    },

    GetSanCeDan(e) {
      this.SanCeDanVisible = true
      this.uidCurrent = e.uid
      console.log(this.uidCurrent)

    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log("看看删除啥", index, row);
      console.log(row.uid, row.reid, this.$store.state.health_station.eid);
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl("/enterprise/enterpriseuserinfo/delUserRelation"),
          method: "get",
          params: this.$http.adornParams({
            eid: this.$store.state.health_station.eid,
            uid: row.uid,
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });

            this.getList()
          } else {
            console.log(data);
            this.$message({
              type: 'info',
              message: data.msg
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, ECG004 }) {
      if (ECG004 == '偏高') {
        return 'warning-row';
      }
      else return '';
    },

    openList() {
      this.$alert('这是一段内容',

        '请选择查看内容', {
        confirmButtonText: '关闭',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${action}`
          });
        }
      });
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
        // eslint-disable-next-line no-unused-vars
        .then(_ => {
          done();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(_ => { });
    },

    // 获取小屋成员List
    getList() {
      this.$http({
        url: this.$http.adornUrl('/enterprise/enterpriseuserinfo/ListUserAll'),
        method: 'get',
        params: this.$http.adornParams({
          eid: this.$store.state.health_station.eid,
        })
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log('小屋list')
          console.log(data)
          this.tableData = data.user_info
        } else {
          // this.getCaptcha()
          this.$message.error(data.msg)
        }
      })
      // this.getlocation()
    },

    goLocation(e) {
      this.$http({
        url: this.$http.adornUrl('/personal/wristband/app/postlocation'),
        method: 'get',
        params: this.$http.adornParams({
          uid: e.uid,
        })
      })
        .then(({ data }) => {
          console.log('看看位置');
          console.log(data);

          // 将数据保存到 locData
          this.locData = data;

          console.log('看看转存');
          console.log(this.locData);

          // 确保在数据成功获取后进行页面跳转
          if (this.locData && this.locData.address && this.locData.latitude && this.locData.longitude) {
            this.$router.push({
              name: 'locationMap',
              query: {
                deviceid: this.locData.deviceid,
                address: this.locData.address,
                latitude: this.locData.latitude,
                longitude: this.locData.longitude
              }
            });
          } else {
            console.error('未能获取到有效的位置数据');
          }
        })
        .catch(error => {
          console.error('获取位置数据时出错', error);
        });
    },

    getlocation() {
      console.log("启动了没？")
      this.tableData.forEach(item => {
        // 检查 item 是否有 uid 属性

        this.$http({
          url: this.$http.adornUrl('/personal/wristband/app/postlocation'),
          method: 'get',
          // params: this.$http.adornParams({
          //   uid: item.uid,
          // })
        }).then(({ data }) => {

          console.log('看看位置')
          console.log(data)
          item.addr = data.address
        })

      })
    },
    // 查询项目
    getECG(e) {
      console.log("看看2023年1月8日");
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistorySingoJump',
        query: {
          uid: e,
        }
      })
    },
    getECGHolter(e) {
      console.log("看看2023年1月8日");
      // console.log(e);
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistorySingoHolterJump',
        query: {
          uid: e,
        }
      })
    },
    get8ECG(e) {
      console.log("看看2023年1月8日");
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightJump',
        query: {
          uid: e,
        }
      })
    },
    get8ECGHolter(e) {
      console.log("看看2023年1月8日");
      // console.log(e);
      this.crossPlatformStatus = true
      this.$router.push({
        name: 'HistoryEightHolterJump',
        query: {
          uid: e,
        }
      })
    },
    getBloodOxygen(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodOxygenJump',
        query: {
          uid: uid,
        }
      })
    },
    getBloodSugar(e) {
      console.log("看看2023年12月4日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodSugarJump',
        query: {
          uid: uid,
        }
      })
    },
    getBodyTemprature(e) {
      console.log("看看2023年12月4日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBodyTemprature',
        query: {
          uid: uid,
        }
      })
    },
    getThreeInOne(e) {
      console.log("看看2023年12月4日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryThreeInOne',
        query: {
          uid: uid,
        }
      })
    },
    getUrine(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryUrineJump',
        query: {
          uid: uid,
        }
      })
    },
    getBloodPressure(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBloodPressureJump',
        query: {
          uid: uid,
        }
      })
    },
    getBodyFat(e) {
      console.log("看看2023年1月8日");
      console.log(e);
      this.crossPlatformStatus = true
      let uid = e
      this.$router.push({
        name: 'HistoryBodyFatJump',
        query: {
          uid: uid,
        }
      })
    },

    addNewUser() {
      if (this.addUid == "") {
        this.$alert('未填写用户信息', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
      } else {
        console.log("看看eId", this.$store.state.health_station.eid, this.userNameOrPhone);
        console.log(typeof (this.$store.state.health_station.eid));
        let that = this
        this.$http({
          url: this.$http.adornUrl("/enterprise/enterpriseuserinfo/addUserRelationPhoneOrName"),
          method: "get",
          params: this.$http.adornParams({
            eid: this.$store.state.health_station.eid,
            phone: that.userNameOrPhone,
          }),
        }).then(({ data }) => {
          if (data && data.code === 0) {
            console.log("看看数据");
            console.log(data);
            this.$message({
              message: "添加成功！",
              type: "success",
            });
            this.userNameOrPhone = ""
            this.getList()
          } else {
            console.log(data);
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        });
      }
    },

    EditAllInfo() {
      this.EditVisible = true
    },
    DownloadAllInfo() {
      this.$http({
        url: this.$http.adornUrl("/enterprise/enterpriseuserinfo/getUserInfoList"),
        method: "get",
        params: this.$http.adornParams({
          eid: this.$store.state.health_station.eid,
        }),
      }).then(({ data }) => {
        if (data && data.code === 0) {
          console.log("看看DownloadAllInfo");
          console.log(data);
          const jsonData = data.user_info;

          const lockedFields = ['uid', 'iid', 'real_name']; // 👈 只锁这些字段

          exportJsonToExcel(jsonData, '用户信息表', lockedFields, this.headerMap);

        } else {
          console.log(data);
          this.$message({
            message: data.msg,
            type: "warning",
          });
        }
      });
    },
    handleFileChange(file) {
      this.file = file.raw; // 存储文件
      this.UploadEditedInfo();
    },
    UploadEditedInfo() {
      if (!this.file) {
        this.$message.warning('请先选择文件');
        return;
      }

      // 将获取到的excel传到服务器中计算
      let formData = new FormData();
      formData.append("file", this.file);
      this.$http({
        url: this.$http.adornUrl("/enterprise/enterpriseuserinfo/uploadExcel2Info"),
        method: "post",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.$message({
            message: '成功存储',
            type: 'success'
          })
          this.getBabyInfo()

        }
        else {
          // this.getCaptcha()
          this.$message.error(data.msg);
        }
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style scoped>
.pageTop {
  font-size: 35px;
}

.drawerTxt {
  margin-left: 10px;
  margin-top: 15px;
  font-size: 25px;
}

.el-table .warning-row {
  background: oldlace;
}
</style>
