// utils/exportExcel.js
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

export function exportJsonToExcel(jsonData, fileName, lockedColumns = [], headerMap = {}) {
  const orderedKeys = Object.keys(headerMap); // 按照 headerMap 的顺序排列

  // 1. 构造数据：第一行为中文表头，第二行为英文字段名，从第三行开始是数据
  const exportArray = [];

  // 第1行：中文表头
  exportArray.push(orderedKeys.map(key => headerMap[key]));

  // 第2行：英文字段
  exportArray.push(orderedKeys);

  // 后续：数据
  jsonData.forEach(item => {
    const row = orderedKeys.map(key => item[key]);
    exportArray.push(row);
  });

  // 2. 转换为 worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(exportArray);

  // 3. 设置锁定列（基于英文列名）
  const columnLockMap = {};
  lockedColumns.forEach(col => {
    columnLockMap[col] = true;
  });

  const range = XLSX.utils.decode_range(worksheet['!ref']);
  for (let C = range.s.c; C <= range.e.c; ++C) {
    const colLetter = XLSX.utils.encode_col(C);
    const headerCell = worksheet[colLetter + "2"]; // 英文字段所在行
    if (!headerCell) continue;

    const fieldName = headerCell.v;
    const isLocked = columnLockMap[fieldName];

    for (let R = 2; R <= range.e.r; ++R) {
      const cellAddress = colLetter + (R + 1);
      const cell = worksheet[cellAddress];
      if (cell) {
        if (!cell.s) cell.s = {};
        cell.s.protection = { locked: !!isLocked };
      }
    }
  }

  // 4. 保护设置
//   worksheet['!protect'] = {
//     password: '123456',
//     selectLockedCells: true,
//     selectUnlockedCells: true,
//   };

  // 5. 写入文件
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const wbout = XLSX.write(workbook, {
    bookType: 'xlsx',
    type: 'array',
    cellStyles: true,
  });

  saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
}
